import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import { maska } from 'maska';

import "@/core/plugins/prismjs";
import "bootstrap";

// import Select2Component
import Select2 from 'vue3-select2-component';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

const app = createApp(App);

app.use(store);
app.use(router);
app.directive('maska', maska);
app.use(ElementPlus);

// Define a new global component called button-counter
app.component('Select2', Select2);

app.component('Datepicker', Datepicker);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

// Add watcher on local change to send to the server the current location
store.watch(
    (state, getters) => getters.getLocale,
    (locale) => {
        app.axios.defaults.params = {}
        app.axios.defaults.params['locale'] = locale;
    });

app.use(i18n);

app.mount("#app");
