import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { Actions } from "@/store/enums/StoreEnums";

export interface Vehicle {
    vehicle: any;
    refresh: number;
}

@Module
export default class VehicleModule extends VuexModule implements Vehicle {
    vehicle = null;
    refresh = 0;

    get getVehicle() {
        return this.vehicle;
    }

    get getRefresh() {
        return this.refresh;
    }

    @Mutation
    updateVehicle(vehicle: any) {
        this.vehicle = vehicle;
    }

    @Mutation
    updateRefresh(refresh: number) {
        this.refresh = refresh;
    }

    @Action
    [Actions.SET_VEHICLE](vehicle: any) {
        this.context.commit('updateVehicle', vehicle);
    }

    @Action
    [Actions.REFRESH_VEHICLES](refresh: number) {
        this.context.commit('updateRefresh', refresh);
    }
}
