
import { defineComponent, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";

export default defineComponent({
    name: "app",
    data() {
        return {
            darkMode: localStorage.getItem("darkMode") === "true",
        };
    },
    created() {
        if (this.darkMode) {
            require("@/assets/sass/plugins.dark.scss");
            require("@/assets/sass/style.dark.scss");
        } else {
            require("@/assets/sass/plugins.scss");
            require("@/assets/sass/style.scss");
        }
    },

    setup() {
        const store = useStore();

        onMounted(() => {
            /**
             * this is to override the layout config using saved data from localStorage
             * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
             */
            store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

            nextTick(() => {
                initializeComponents();
            });
        });
    },
});
