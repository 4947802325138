import { createI18n } from "vue-i18n/index";

const messages = {
    en: {
        dashboard: "Tableau de board",
        account: "Compte",
        settings: "Paramètres",
        signIn: "S'identifier",
        signUp: "Inscrivez-vous",
        passwordReset: "Réinitialisation du mot de passe",
        error404: "Erreur 404",
        error500: "Erreur 500",

        title: "Title",
        currencyDz: "DA",
        ExternalLabel: "externe",
        hour:"heure",
        unit: "Unité",
        undefined: "Aucun",
        genderMale: "Male",
        genderFemale: "Female",
        operationIncrease: "Addition",
        operationDecrease: "Soustraction",
        civilStatusMarried: "Married",
        civilStatusDivorced: "Divorced",
        civilStatusSingle: "Single",
        civilStatusWidower: "Widower",
        availabilityStockout: "Rupture",
        availabilityAvailable: "Disponible",
        availabilityStocklow: "Seuil faible",

        actions: "Actions",
        yes:"Oui",
        no:"Non",
        search: "Rechercher",
        searchHeadPark:"Rechercher employée",
        searchHeadBranche:"Chef branche",
        add: "Add",
        select: "Select",
        confirm: "Confirm",
        cancel: "Cancel",
        save: "Save",
        loading: "Loading...",
        pleaseWait: "Please wait...",

        now: "Now",
        pickDate: "Pick a date",

        infoHeading: "Info !",

        errorHeading: "Error !",
        errorConnectionMessage: "An error has occurred, please check your internet connection. Then reload the page.",

        warningHeading: "Warning !",

        tableEmpty: "No items found to display in table.",
        paginationText: "Total items {total}",
        paginationSearchText: "Total items found {total} on {exists} exisiting",

        labelQueryString: "Key words",
        labelDescription: "Description",
        labelName: "Name",

        idCard1Label:"Carte nationale",
        idCard2Label:"Passport",
        idCard3Label:"Permis de conduire",

        carType1Label:"VIP",
        carType2Label:"Ordinnaire",
        carType3Label:"Missionnaire",
        guestsLabel:"Visiteurs",

        masterDataHeading: "Master Data",
        referencialsHeading: "Référentiels",
        externalCompaniesManagementHeading: "Gestion Des Entreprises Externes",
        employeesManagementHeading: "Gestion Des Employés",
        stockManagementHeading: "Gestion de stock",
        analyticAccountsManagementHeading: "Gestion Des Comptes Analytiques",
        servicesHeading: "Catalogue Services",
        settingsHeading: "Configuration",
        requestHeading: "Requête",
        fleetHeading: "Flotte",

        structureHeading: "Structures Management",
        structureEditHeading: "Edit structure",
        structureTableCaption: "Structures list",
        structureL1Label: "Direction",
        structureL2Label: "Division",
        structureL3Label: "Services",
        structureL4Label: "Service",
        structureL5Label: "Section",
        structureL1NameLabel: "Nom direction",
        structureL1DescriptionLabel: "Description direction",
        structureL2NameLabel: "Nom division",
        structureL2DescriptionLabel: "Description division",
        structureL3NameLabel: "Nom services",
        structureL3DescriptionLabel: "Description services",
        structureL4NameLabel: "Nom service",
        structureL4DescriptionLabel: "Description service",
        structureL5NameLabel: "Nom section",
        structureL5DescriptionLabel: "Description section",
        structureConfirmDelete: "Etes-vous sûre de vouloir supprimer cette structure ?",
        structureDoesNotExist: "Cette structure n'existe pas !",

        infrastructureHeading: "Infrastructures Management",
        infrastructureEditHeading: "Edit infrastructure",
        infrastructureTableCaption: "Infrastructures list",
        infrastructureL1Label: "Region",
        infrastructureL2Label: "Site",
        infrastructureL3Label: "Zone",
        infrastructureL4Label: "Bloc",
        infrastructureL5Label: "Unité",
        infrastructureL1NameLabel: "Nom region",
        infrastructureL1DescriptionLabel: "Description region",
        infrastructureL2NameLabel: "Nom site",
        infrastructureL2DescriptionLabel: "Description site",
        infrastructureL3NameLabel: "Nom zone",
        infrastructureL3DescriptionLabel: "Description zone",
        infrastructureL4NameLabel: "Nom bloc",
        infrastructureL4DescriptionLabel: "Description bloc",
        infrastructureL5NameLabel: "Nom unité",
        infrastructureL5DescriptionLabel: "Description unité",
        infrastructureConfirmDelete: "Etes-vous sûre de vouloir supprimer cette infrastructure ?",
        infrastructureDoesNotExist: "Cette infrastructure n'existe pas !",

        supplierHeading: "Liste des prestataires",
        supplierLabel: "Prestataire",
        supplierManagementHeading: "Prestataires ou Fournisseurs",
        supplierTableCaption: "Liste des prestataires",
        supplierSearchHeading: "Rechercher un préstataire",
        supplierEditHeading: "Gérer un préstataire",
        supplierGeneralDataHeading: "Infos Générales",
        supplierRegulatoryDataHeading: "Infos Réglementaires",
        supplierConfirmDelete: "Etes-vous sûre de vouloir supprimer ce partenaire ?",
        supplierIdLabel: "ID",
        supplierCodeLabel: "Code prestataire",
        supplierNameLabel: "Nom de prestataire",
        supplierCompanyLabel: "Raison social",
        supplierCapitalLabel: "Capital social",
        supplierStatusLabel: "Statut d'entreprise",
        supplierEmailLabel: "E-mail",
        supplierPhoneLabel: "Téléphone",
        supplierExternalLabel: "Prestataire externe",
        supplierHqLabel: "Maison Mére",
        supplierHqNameLabel: "Nom Maison Mére",
        supplierHqEmailLabel: "E-mail Maison Mére",
        supplierHqPhoneLabel: "Téléphone Maison Mére",
        supplierHqAddressLabel: "Adresse Maison Mére",
        supplierActivityFieldLabel: "Secteur d'activité",
        supplierActivityCodeLabel: "Code d'activité",
        supplierOfficeAddressLabel: "Adresse Siège sociale",
        supplierCommercialAddressLabel: "Adresse Commerciale",
        supplierCommercialAddress2Label: "Adresse Commerciale 2",
        supplierNifLabel: "N° IF",
        supplierNisLabel: "N° IS",
        supplierNrcLabel: "N° RC",

        supplierIntraHeading: "Liste des prestataires",
        supplierIntraManagementHeading: "Intra-entreprises",
        supplierIntraTableCaption: "Liste des prestataires",
        supplierIntraSearchHeading: "Rechercher un préstataire",
        supplierIntraEditHeading: "Gérer un préstataire",
        supplierIntraConfirmDelete: "Etes-vous sûre de vouloir supprimer ce partenaire ?",
        supplierIntraIdLabel: "ID",
        supplierIntraNameLabel: "Nom d'entreprise",
        supplierIntraPhoneLabel: "Téléphone",
        supplierIntraAddressLabel: "Adresse",
        supplierIntraNoteLabel: "Commentaires",

        employeeHeading: "Liste des employés",
        employeeManagementHeading: "Internes",
        employeeTableCaption: "Liste des employés",
        employeeSearchHeading: "Rechercher un employé",
        employeeEditHeading: "Gérer un employé",
        employeeConfirmDelete: "Etes-vous sûre de vouloir supprimer cet employé ?",
        employeeIdLabel: "ID",
        employeeCodeLabel: "Matricule",
        employeeLastNameLabel: "Last name",
        employeeFirstNameLabel: "First name",
        employeeFullNameLabel: "Fullname",
        employeeGenderLabel: "Gender",
        employeeCivilStatusLabel: "Etat civile",
        employeeIdNumberLabel: "N° p. identité",
        employeePassportNumberLabel: "N° passeport",
        employeeEmailLabel: "E-mail",
        employeePhoneLabel: "Phone",
        employeeAddressLabel: "Address",
        employeeStructureTypeLabel: "Type de Structure",
        employeeStructureCodeLabel: "Structure",
        employeePositionIdLabel: "Job",
        employeeNationalityLabel: "Nationality",
        employeeAnalyticAccountCodeLabel: "Compte Analytique",
        employeeIsUserLabel: "User",
        employeeIsDriverLabel: "Driver",
        employeeIsHeadLabel: "Head",

        employeeIntraHeading: "Liste des employés",
        employeeIntraManagementHeading: "Intra-entreprise",
        employeeIntraTableCaption: "Liste des employés",
        employeeIntraSearchHeading: "Rechercher un employé",
        employeeIntraEditHeading: "Gérer un employé",
        employeeIntraConfirmDelete: "Etes-vous sûre de vouloir supprimer cet employé ?",
        employeeIntraIdLabel: "ID",
        employeeIntraSupplierIntraIdLabel: "Structure",
        employeeIntraCodeLabel: "Matricule",
        employeeIntraLastNameLabel: "Nom",
        employeeIntraFirstNameLabel: "Prénom",
        employeeIntraFullNameLabel: "Nom et Prénom",
        employeeIntraGenderLabel: "Civilité",
        employeeIntraIdNumberLabel: "N° p. identité",
        employeeIntraNationalityLabel: "Nationalité",
        employeeIntraJobLabel: "Poste",

        employeeSupplierHeading: "Liste des employés",
        employeeSupplierManagementHeading: "Prestataire",
        employeeSupplierTableCaption: "Liste des employés",
        employeeSupplierSearchHeading: "Rechercher un employé",
        employeeSupplierEditHeading: "Gérer un employé",
        employeeSupplierConfirmDelete: "Etes-vous sûre de vouloir supprimer cet employé ?",
        employeeSupplierIdLabel: "ID",
        employeeSupplierSupplierIdLabel: "Prestataire",
        employeeSupplierCodeLabel: "Matricule",
        employeeSupplierLastNameLabel: "Nom",
        employeeSupplierFirstNameLabel: "Prénom",
        employeeSupplierFullNameLabel: "Nom et Prénom",
        employeeSupplierGenderLabel: "Civilité",
        employeeSupplierCivilStatusLabel: "Etat civile",
        employeeSupplierEmailLabel: "E-mail",
        employeeSupplierPhoneLabel: "Téléphone",
        employeeSupplierAddressLabel: "Adresse",
        employeeSupplierIdNumberLabel: "N° p. identité",
        employeeSupplierPassportNumberLabel: "N° passeport",
        employeeSupplierPositionIdLabel: "Poste",
        employeeSupplierNationalityLabel: "Nationalité",
        employeeSupplierIsUserLabel: "Utilisateur",
        employeeSupplierIsDriverLabel: "Chauffeur",

        warehouseHeading: "Magasins",
        warehouseManagementHeading: "Magasins",
        warehouseTableCaption: "Liste des magasins",
        warehouseSearchHeading: "Rechercher un magasin",
        warehouseEditHeading: "Gérer un magasin",
        warehouseConfirmDelete: "Etes-vous sûre de vouloir supprimer ce magasin ?",
        warehouseIdLabel: "ID",
        warehouseNameLabel: "Désignation",
        warehouseInfrastructureL1IdLabel: "Region",
        warehouseInfrastructureL2IdLabel: "Site",
        warehouseInfrastructureL3IdLabel: "Zone",
        warehouseInfrastructureIdLabel: "Bloc",
        warehouseTypeIdLabel: "Type",
        warehouseDescriptionLabel: "Description",

        productHeading: "Produits",
        productManagementHeading: "Produits",
        productTableCaption: "Liste des produits",
        productSearchHeading: "Rechercher un employé",
        productEditHeading: "Gérer un produit",
        productConfirmDelete: "Etes-vous sûre de vouloir supprimer ce produit ?",
        productCheckAttribute: "Veuillez d'abord créer des attributs de produit !",
        productIdLabel: "ID",
        productNameLabel: "Désignation",
        productBarcodeLabel: "Code-barres",
        productCodeLabel: "Référence",
        productBrandLabel: "Marque",
        productCategoryIdLabel: "Catégorie",
        productLowStockLevelLabel: "Seuil stock faible",
        productHasLotLabel: "Gestion par lot",
        productPerishableLabel: "Produit périssable",
        productHasVariationsLabel: "Produit variable",
        productAttributesLabel: "Attributs",
        productVariationsLabel: "Variations",
        productDescriptionLabel: "Description",
        productValueLabel: "Valeur",
        productQuantityLabel: "Quantité",
        productAvailabilityLabel: "Disponibilité",

        productCategoryHeading: "Product Categories",
        productCategoryEditHeading: "Edit product category",
        productCategoryTableCaption: "Product Categories list",
        productCategoryL1Label: "Category",
        productCategoryL2Label: "Sub-Category",
        productCategoryL3Label: "Familly",
        productCategoryL4Label: "Sub-Familly",
        productCategoryL1NameLabel: "Nom catégorie",
        productCategoryL1DescriptionLabel: "Description catégorie",
        productCategoryL2NameLabel: "Nom sous-catégorie",
        productCategoryL2DescriptionLabel: "Description sous-catégorie",
        productCategoryL3NameLabel: "Nom famille",
        productCategoryL3DescriptionLabel: "Description famille",
        productCategoryL4NameLabel: "Nom sous-famille",
        productCategoryL4DescriptionLabel: "Description sous-famille",
        productCategoryConfirmDelete: "Etes-vous sûre de vouloir supprimer cette catégorie ?",
        productCategoryDoesNotExist: "Cette catégorie n'existe pas !",

        productAttributeHeading: "Attributs de produits",
        productAttributeManagementHeading: "Attributs de produits",
        productAttributeTableCaption: "Liste des attributs",
        productAttributeEditHeading: "Gérer un attribut",
        productAttributeConfirmDelete: "Etes-vous sûre de vouloir supprimer cet attribut ?",
        productAttributeIdLabel: "ID",
        productAttributeNameLabel: "Nom",
        productAttributeOptionsLabel: "Options",

        productInputHeading: "Entrées de stock",
        productInputManagementHeading: "Entrées de stock",
        productInputTableCaption: "Historique des entrées de stock",
        productInputEditHeading: "Gérer une entrée de stock",
        productInputConfirmDelete: "Etes-vous sûre de vouloir supprimer cette entrée de stock ?",
        productInputIdLabel: "ID",
        productInputDateLabel: "Date",
        productInputCodeLabel: "Référence",
        productInputDfCodeLabel: "Bon de livraison",
        productInputPoCodeLabel: "Bon de commande",
        productInputDescriptionLabel: "Description",
        productInputProductsLabel: "Produits",
        productInputProductIdLabel: "Produit",
        productInputQuantityLabel: "Quantité",
        productInputWarehouseIdLabel: "Magasin",
        productInputLotCodeLabel: "N° lot",
        productInputExpiresLabel: "Expire le",
        productInputUnitPriceLabel: "Prix Unitaire",

        productOutputHeading: "Sorties de stock",
        productOutputManagementHeading: "Sorties de stock",
        productOutputTableCaption: "Historique des sorties de stock",
        productOutputEditHeading: "Gérer une sortie de stock",
        productOutputSearchHeading: "Rechercher une sortie de stock",
        productOutputConfirmDelete: "Etes-vous sûre de vouloir supprimer cette sortie de stock ?",
        productOutputIdLabel: "ID",
        productOutputStatusLabel: "Statut",
        productOutputDatetimeLabel: "Date et heure",
        productOutputEmployeeIdLabel: "Employé",
        productOutputKnownEmployeeLabel: "Interne",
        productOutputLastNameLabel: "Nom Employé",
        productOutputFirstNameLabel: "Prénom Employé",
        productOutputJobLabel: "Position Employé",
        productOutputNoteLabel: "Remarque",
        productOutputProductsLabel: "Produits",
        productOutputProductIdLabel: "Produit",
        productOutputQuantityLabel: "Quantité",
        productOutputLabel: "Sortir",

        productAdjustmentHeading: "Ajustements de stock",
        productAdjustmentManagementHeading: "Ajustements de stock",
        productAdjustmentTableCaption: "Historique des ajustements de stock",
        productAdjustmentEditHeading: "Gérer un ajustement de stock",
        productAdjustmentConfirmDelete: "Etes-vous sûre de vouloir supprimer cet ajustement de stock ?",
        productAdjustmentEditLabel: "Ajouter",
        productAdjustmentListLabel: "Historique",
        productAdjustmentProductIdLabel: "Produit",
        productAdjustmentStatusLabel: "Statut",
        productAdjustmentQuantityLabel: "Quantité",
        productAdjustmentOperationLabel: "Sens",
        productAdjustmentNoteLabel: "Remarque",
        productAdjustmentVariationsLabel: "Variations produit",
        productAdjustmentVariationIdLabel: "Variation produit",

        analyticAccountHeading: "Liste des comptes analytiques",
        analyticAccountManagementHeading: "Comptes internes",
        analyticAccountManagementExternHeading: "Comptes externes",
        analyticAccountTableCaption: "Liste des comptes",
        analyticAccountSearchHeading: "Rechercher un compte analytique",
        analyticAccountEditHeading: "Gérer un compte analytique",
        analyticAccountConfirmDelete: "Etes-vous sûre de vouloir supprimer ce compte analytique ?",
        analyticAccountIdLabel: "ID",
        analyticAccountCodeLabel: "Compte",
        analyticAccountNameLabel: "Désignation",
        analyticAccountStructureTypeLabel: "Type de Structure",
        analyticAccountStructureCodeLabel: "Structure",
        analyticAccountDescriptionLabel: "Description",

        analyticAccountIntraHeading: "Liste des comptes analytiques",
        analyticAccountIntraManagementHeading: "Intra-entreprises",
        analyticAccountIntraTableCaption: "Liste des comptes",
        analyticAccountIntraSearchHeading: "Rechercher un compte analytique",
        analyticAccountIntraEditHeading: "Gérer un compte analytique",
        analyticAccountIntraConfirmDelete: "Etes-vous sûre de vouloir supprimer ce compte analytique ?",
        analyticAccountIntraIdLabel: "ID",
        analyticAccountIntraCodeLabel: "Compte",
        analyticAccountIntraNameLabel: "Désignation",
        analyticAccountIntraSupplierIntraIdLabel: "Structure",
        analyticAccountIntraDescriptionLabel: "Description",

        vehicleHeading: "Liste des véhicules",
        vehicleManagementHeading: "Véhicules",
        vehicleTableCaption: "Liste des véhicules",
        vehicleSearchHeading: "Rechercher un véhicule",
        vehicleEditHeading: "Gérer un véhicule",
        vehicleConfirmDelete: "Etes-vous sûre de vouloir supprimer ce véhicule ?",
        vehicleInfosHeading: "Infos",
        vehicleInsurancesHeading: "Assurances",
        vehicleControlsHeading: "Contrôles",
        vehicleAssignmentsHeading: "Affectation",
        vehicleMissionsHeading: "Missions",
        vehicleRepairsHeading: "Maintenances",
        vehicleIdLabel: "ID",
        vehicleExternalLabel: "Externe",
        vehicleTypeIdLabel: "Type",
        vehicleBrandIdLabel: "Marque",
        vehicleModelIdLabel: "Modèle",
        vehicleYearLabel: "Année",
        vehicleColorIdLabel: "Couleur",
        vehicleFuelIdLabel: "Energie",
        vehicleGearboxIdLabel: "Boîte à vitesses",
        vehicleEpcLabel: "Code EPC",
        vehicleVinLabel: "N° châssis",
        vehicleVrnLabel: "N° d'immatriculation",
        vehicleNoteLabel: "Remarques",

        vehicleInsuranceHeading: "Historique des assurances",
        vehicleInsuranceManagementHeading: "Assurances",
        vehicleInsuranceTableCaption: "Historique des assurances",
        vehicleMaintenanceTableCaption: "Historique des maintenances",
        vehicleInsuranceEditHeading: "Gérer un assurance",
        vehicleInsuranceConfirmDelete: "Etes-vous sûre de vouloir supprimer cette assurance ?",
        vehicleInsuranceIdLabel: "ID",
        vehicleInsuranceVehicleIdLabel: "Véhicule",
        vehicleInsuranceCompanyIdLabel: "Société d'assurance",
        vehicleInsuranceAgencyNameLabel: "Agence",
        vehicleInsuranceAgencyCodeLabel: "N° Agence",
        vehicleInsurancePolicyNumberLabel: "Numéro de police",
        vehicleInsuranceBeginsLabel: "Date début",
        vehicleInsuranceEndsLabel: "Date fin",
        vehicleInsuranceAmountLabel: "Montant payé",

        vehicleDamageHeading: "Sinistres véhicule",
        vehicleDamageManagementHeading: "Sinistres véhicule",
        vehicleDamageTableCaption: "Historique des sinistres véhicule",
        vehicleDamageEditHeading: "Gérer un sinistre véhicule",
        vehicleDamageConfirmDelete: "Etes-vous sûre de vouloir supprimer ce sinistre véhicule ?",
        vehicleDamageEditLabel: "Ajouter",
        vehicleDamageListLabel: "Historique",
        vehicleDamageIdLabel: "ID",
        vehicleDamageVehicleIdLabel: "Véhicule",
        vehicleDamageInsuranceIdLabel: "Police d'assurance",
        vehicleDamageDescriptionLabel: "Dégâts",
        vehicleDamageDateLabel: "Date",
        vehicleDamageConditionIdLabel: "Etat du véhicule",
        vehicleDamageLocationLabel: "Endroit",
        vehicleDamageLocationFromLabel: "Allant de",
        vehicleDamageLocationToLabel: "vers",

        vehicleControlHeading: "Historique des contrôles techniques",
        vehicleControlManagementHeading: "Contrôles techniques",
        vehicleControlTableCaption: "Historique des contrôles techniques",
        vehicleControlEditHeading: "Gérer un contrôle technique",
        vehicleControlConfirmDelete: "Etes-vous sûre de vouloir supprimer ce contrôle technique ?",
        vehicleControlIdLabel: "ID",
        vehicleControlVehicleIdLabel: "Véhicule",
        vehicleControlAgencyIdLabel: "Agence",
        vehicleControlCodeLabel: "N° de contrôle",
        vehicleControlDateLabel: "Date",
        vehicleControlExpiresLabel: "Expiration",
        vehicleControlCheckpointsLabel: "Points de contrôle",
        vehicleControlReinspectionLabel: "Contre visite",
        vehicleControlReinspectionCheckpointsLabel: "Points de contrôle à réviser",
        vehicleControlReinspectionNoteLabel: "Remarque",

        vehicleAssignmentHeading: "Historique des affectations",
        vehicleAssignmentManagementHeading: "Affectations",
        vehicleAssignmentTableCaption: "Historique des affectations",
        vehicleAssignmentEditHeading: "Gérer une affectation",
        vehicleAssignmentConfirmDelete: "Etes-vous sûre de vouloir supprimer cette affectation ?",
        vehicleAssignmentStructureTypeLabel: "Type de Structure",
        vehicleAssignmentStructureCodeLabel: "Structure",
        vehicleAssignmentIdLabel: "ID",
        vehicleAssignmentVehicleIdLabel: "Véhicule",
        vehicleAssignmentEmployeeIdLabel: "Employé",
        vehicleAssignmentDriverIdLabel: "Conducteur",
        vehicleAssignmentBeginsLabel: "Date début",
        vehicleAssignmentEndsLabel: "Date fin",


        vehicleMaintenanceEditHeading:"Gérer une maintenance",
        vehicleMaintenanceDateBegin:"Date debut",
        vehicleMaintenanceDateEnd:"Date fin",
        vehicleMaintenanceTypeId:"Type de maintenance",
        vehicleMaintenanceBreakdownId:"Type de panne",
        vehicleMaintenanceEmployeeId:"Employé intervenant",
        vehicleMaintenanceEvalMaint:"Evaluation de la maintenance",
        vehicleMaintenanceSparePartId:"Pièce de rechange",
        vehicleMaintenanceCostMaint:"Coût de la maintenance",
        vehicleMaintenanceNbrMaint:"Nombre d'heure de la maintenance",
        vehicleMaintenanceStatus:"État",
        vehicleMaintenanceAnalyticAccount:"Compte analytique",
        vehicleMaintAlertMsg:"Veuillez remplir les champs sous dessus après avoir terminé la maintenance",




        vehicleMissionHeading: "Historique des missions",
        vehicleMissionManagementHeading: "Missions",
        vehicleMissionTableCaption: "Historique des missions",
        vehicleMissionEditHeading: "Gérer une mission",
        vehicleMissionConfirmDelete: "Etes-vous sûre de vouloir supprimer cette mission ?",
        vehicleMissionIdLabel: "ID",
        vehicleMissionDriverIdLabel: "Conducteur",
        vehicleMissionItineraryIdLabel: "Iténeraire",
        vehicleMissionExternalLabel: "Externe",
        vehicleMissionStructureTypeLabel: "Type de Structure",
        vehicleMissionStructureCodeLabel: "Structure",
        vehicleMissionSupplierIntraIdLabel: "Entreprises Externes",
        vehicleMissionBeginsLabel: "Date début",
        vehicleMissionEndsLabel: "Date fin",

        requestMaintenanceManagementHeading: "Gestion Des Requêtes (Maintenance) ",
        maintenanceHeading: "Liste des requêtes",
        maintenanceManagementHeading: "Requêtes",
        maintenanceTableCaption: "Liste des requêtes (Maintenance)",
        maintenanceSearchHeading: "Rechercher une requête",
        maintenanceEditHeading: "Gérer une requête",
        maintenanceOrderHeading: "Gérer une requête (Ordre de maintenance)",
        maintenanceConfirmDelete: "Etes-vous sûre de vouloir supprimer cette requêtes (Maintenance) ?",
        maintenanceStateBtnValidated:"Validé",
        maintenanceStateBtnAccepted:"Accepté",
        maintenanceStateBtnProgress:"En cours",
        maintenanceStateBtnRejected:"Rejeté",
        maintenanceStateLabel:"Mise a jour statut de la demande",



        requestMaintenanceTypeIdLabel: "Type de maintenance",
        requestMaintenanceEmergencyLabel: "Urgence",
        requestMaintenanceDescriptionLabel: "Note",
        requestMaintenanceFileLabel:"Attachement",
        requestMaintenanceStateLabel: "État",
        requestMaintenanceSearchHeading:"Rechercher une requête (Maintenance)",
        requestMaintenanceBreakdownIdLabel: "Type de panne",
        requestMaintenanceEmployeeIdLabel: "Employé",
        requestMaintenanceanalyticAccountsLabel:"Compte analytic",
        requestMaintenanceAnalyticAccountTypeLabel:"Type de compte analytic",
        requestMaintenanceExecutionTypeLabel:"Responsable d'exécution",
        requestMaintenanceExecutionTypeSonatrachLabel:"SONATRACH",
        requestMaintenanceExecutionTypeProviderLabel:"Prestataire",
        requestMaintenanceParkLabel:"Atelier",
        requestMaintenanceParkHeadLabel:"Chef atelier",
        requestMaintenanceDisplayFileLabel:"Voir le fichier",

        orderMaintenanceBeginDateLabel:"Date début d'intervnetion",
        orderMaintenanceEndDateLabel:"Date fin d'intervnetion",
        orderMaintenanceHourLabel:"Heure fin d'intervnetion",
        orderMaintenanceNoteLabel:"Note",
        orderMaintenanceEmployeeIdLabel: "Employé",



        requestTransportManagementHeading: "Gestion Des Requêtes (Transport) ",
        transportHeading: "Liste des requêtes",
        transportManagementHeading: "Requêtes",
        transportTableCaption: "Liste des requêtes (Transport)",
        transportSearchHeading: "Rechercher une requête",
        transportEditHeading: "Gérer une requête",
        transportConfirmDelete: "Etes-vous sûre de vouloir supprimer cette requêtes (Transport) ?",
        transportStateBtnValidated:"Validé",
        transportStateBtnProgress:"En cours",
        transportStateBtnRejected:"Rejeté",

        requestTransportMotifLabel:"Motif de la demande",
        requestTransportFromLabel:"Arrive de",
        requestTransportArrivalDateLabel:"Date d'arrivée",
        requestTransportDepartureDateLabel:"Date de départ",





        emergencyType1: "Urgent",
        emergencyType2: "Devrait être fait bientôt",
        emergencyType3: "Peut attendre les vacances",


        branchesLabel:"Branches",
        parkEditHeading: "Gérer un atelier",
        parkConfirmDelete:"Etes-vous sûre de vouloir supprimer cette atelier ?",


        parkManagementHeading: "Gestion Des Ateliers",
        parkNameLabel: "Nom",
        parkPhoneLabel: "Numéro de Téléphone",
        parkEmployeeIdLabel:"Chef atelier",
        parkAddresseLabel: "Adresse",
        parkInfraLabel: "Infrastructure",
        parkInfraTypeLabel: "Type infrastructure",
        parkHeadLabel: "Chef atelier",
        parkDescLabel: "Note",
        parkInfraType1Label:"Region",
        parkInfraType2Label:"Site",
        parkInfraType3Label:"Zone",
        parkInfraType4Label:"Bloc",
        parkInfraType5Label:"Unité",
        parkBrancheNameLabel:"Nom",
        parkBrancheHeadingLabel:"Rubrique",
        parkBranchePhoneLabel:"Numéro",
        parkBrancheHeadLabel:"Chef branche",


        contractManagementHeading: "Contrats",
        contractConfirmDelete: "Etes-vous sûre de vouloir supprimer cette contrat ?",
        contractCodeLabel:"N° Contrat",
        contractDesignationLabel:"Désignation",
        contractObjectLabel:"Objet",
        contractSupplierTypeLabel:"Type de Prestation",
        contractSupplierLabel:"Prestataire",
        contractFileLabel:"Attachement",
        contractDateBeginLabel:"Date début de validité",
        contractDateEndLabel:"Date d'expiration",
        contractDateSignLabel:"Date de signature",
        // contractDateEffLabel:"Date d'entrée en vigueur",
        contractDateEffLabel:"Date effective",
        contractClientDomLabel:"Domiciliation Client",
        contractClientRepLabel:"Représentant Client ",
        contractSupplierDomLabel:"Domiciliation Prestataire",
        contractSupplierRepLabel:"Représentant Prestataire",
        contractNoteLabel:"Note",

        contractPriceScheduleMaintTableCaption: "Historique des maintenances",
        contractPriceScheduleRentTableCaption: "Historique des locations",

        contractPriceScheduleRentTypeIdLabel:"Type véhicule",
        contractPriceScheduleRentBrandLabel:"Marque",
        contractPriceScheduleRentEstimatedPriceLabel:"Prix estimé",
        contractPriceScheduleRentContractualQuantityLabel:"Quantité contractuelle",
        contractPriceScheduleRentAnnualQuantityLabel:"Quantité annuel",
        contractPriceScheduleRentForecastQuantityLabel:"Quantité estimé",

        contractAspectTotalAmountLabel:"Montant Total",
        contractAspectLocationAmountLabel:"Montant Location",
        contractAspectMaintenanceAmountLabel:"Montant Maintenance",
        contractAspectLocationLabel:"Location",
        contractAspectMaintenanceLabel:"Maintenance",
        historycontractAspectLocationLabel:"Historique des Location",
        historycontractAspectMaintenanceLabel:"Historique des Maintenance",

        contractTableCaption: "Liste des contrats",
        contractEditHeading: "Gérer une contrat",
        contractContractualScopeHeading:"Périmètre Contractuel",
        contractFinAspectHeading:"Aspects Financiers",
        contractPriceHeading:"Bordereau des prix",
        contractGuaranteeHeading:"Pénalités & Garantie",
        contractAlertHeading:"Notifications & Alertes",
        contractSynopHeading:"Synoptique",




        dropdownOptionHeading: "Listes déroulantes",
        dropdownOptionManagementHeading: "Contrôles techniques",
        dropdownOptionTableCaption: "Liste des options",
        dropdownOptionSelectOptionMessage: "Veuillez choisir quel élément de liste déroulante vous voulez modifier.",
        dropdownOptionIdLabel: "ID",
        dropdownOptionNameLabel: "Désignation",
        dropdownOptionDescriptionLabel: "Description",
        dropdownOptionParentLabel: "Parent",
        dropdownOptionCreatedByLabel: "Créé par",
        dropdownOptionDisplayLabel: "Affiché",

        roleHeading: "Liste des rôles",
        roleManagementHeading: "Rôles",
        roleTableCaption: "Liste des rôles",
        roleSearchHeading: "Rechercher un rôle",
        roleEditHeading: "Gérer un rôle",
        roleConfirmDelete: "Etes-vous sûre de vouloir supprimer ce rôle ?",
        roleIdLabel: "ID",
        roleNameLabel: "Nom",
    },
    fr: {
        dashboard: "Tableau de board",
        account: "Compte",
        settings: "Paramètres",
        signIn: "S'identifier",
        signUp: "Inscrivez-vous",
        passwordReset: "Réinitialisation du mot de passe",
        error404: "Erreur 404",
        error500: "Erreur 500",

        title: "Désignation",
        currencyDz: "DA",
        ExternalLabel: "externe",
        hour:"heure",
        unit: "Unité",
        undefined: "Aucun",
        genderMale: "Homme",
        genderFemale: "Femme",
        operationIncrease: "Addition",
        operationDecrease: "Soustraction",
        civilStatusMarried: "Marié",
        civilStatusDivorced: "Divorcé",
        civilStatusSingle: "Célibataire",
        civilStatusWidower: "Veuf",
        availabilityStockout: "Rupture",
        availabilityAvailable: "Disponible",
        availabilityStocklow: "Seuil faible",

        actions: "Actions",
        yes:"Oui",
        no:"Non",
        search: "Rechercher",
        searchHeadPark:"Rechercher employée",
        searchHeadBranche: "Chef branche",
        add: "Ajouter",
        save: "Enregistrer",
        select: "Choisir",
        confirm: "Confirmer",
        cancel: "Annuler",
        loading: "Chargement...",
        pleaseWait: "Veuillez patienter...",

        now: "Aujourd'hui",
        pickDate: "Définir une date",
        pickHour: "Définir l'heure",

        infoHeading: "Info !",

        errorHeading: "Erreur !",
        errorConnectionMessage: "Une erreur est survenue, veuillez vérifier votre connexion internet. Puis, recharger la page.",

        warningHeading: "Attention !",

        tableEmpty: "Aucun élément trouvé à afficher dans le tableau.",
        paginationText: "Au total {total} éléments",
        paginationSearchText: "Au total {total} trouvés sur {exists} éléments",

        labelQueryString: "Mots clés",
        labelDescription: "Description",
        labelName: "Nom",

        idCard1Label:"Carte nationale",
        idCard2Label:"Passport",
        idCard3Label:"Permis de conduire",

        carType1Label:"VIP",
        carType2Label:"Ordinnaire",
        carType3Label:"Missionnaire",
        guestsLabel:"Visiteurs",

        masterDataHeading: "Master Data",
        referencialsHeading: "Référentiels",
        externalCompaniesManagementHeading: "Gestion Des Entreprises Externes",
        employeesManagementHeading: "Gestion Des Employés",
        stockManagementHeading: "Gestion de stock",
        analyticAccountsManagementHeading: "Gestion Des Comptes Analytiques",
        servicesHeading: "Catalogue Services",
        settingsHeading: "Configuration",
        requestHeading: "Requête",
        fleetHeading: "Flotte",

        structureHeading: "Gestion Des Structures",
        structureEditHeading: "Gérer la structure",
        structureTableCaption: "Liste des structures",
        structureL1Label: "Direction",
        structureL2Label: "Division",
        structureL3Label: "Services",
        structureL4Label: "Service",
        structureL5Label: "Section",
        structureL1NameLabel: "Nom direction",
        structureL1DescriptionLabel: "Description direction",
        structureL2NameLabel: "Nom division",
        structureL2DescriptionLabel: "Description division",
        structureL3NameLabel: "Nom services",
        structureL3DescriptionLabel: "Description services",
        structureL4NameLabel: "Nom service",
        structureL4DescriptionLabel: "Description service",
        structureL5NameLabel: "Nom section",
        structureL5DescriptionLabel: "Description section",
        structureConfirmDelete: "Etes-vous sûre de vouloir supprimer cette structure ?",
        structureDoesNotExist: "Cette structure n'existe pas !",

        infrastructureHeading: "Gestion Des Infrastructures",
        infrastructureEditHeading: "Gérer une infrastrucutre",
        infrastructureTableCaption: "Liste des infrastructures",
        infrastructureL1Label: "Region",
        infrastructureL2Label: "Site",
        infrastructureL3Label: "Zone",
        infrastructureL4Label: "Bloc",
        infrastructureL5Label: "Unité",
        infrastructureL1NameLabel: "Nom region",
        infrastructureL1DescriptionLabel: "Description region",
        infrastructureL2NameLabel: "Nom site",
        infrastructureL2DescriptionLabel: "Description site",
        infrastructureL3NameLabel: "Nom zone",
        infrastructureL3DescriptionLabel: "Description zone",
        infrastructureL4NameLabel: "Nom bloc",
        infrastructureL4DescriptionLabel: "Description bloc",
        infrastructureL5NameLabel: "Nom unité",
        infrastructureL5DescriptionLabel: "Description unité",
        infrastructureConfirmDelete: "Etes-vous sûre de vouloir supprimer cette infrastructure ?",
        infrastructureDoesNotExist: "Cette infrastructure n'existe pas !",

        supplierHeading: "Liste des prestataires",
        supplierLabel: "Prestataire",
        supplierManagementHeading: "Prestataires ou Fournisseurs",
        supplierTableCaption: "Liste des prestataires",
        supplierSearchHeading: "Rechercher un préstataire",
        supplierEditHeading: "Gérer un préstataire",
        supplierGeneralDataHeading: "Infos Générales",
        supplierRegulatoryDataHeading: "Infos Réglementaires",
        supplierConfirmDelete: "Etes-vous sûre de vouloir supprimer ce partenaire ?",
        supplierIdLabel: "ID",
        supplierCodeLabel: "Code prestataire",
        supplierNameLabel: "Nom de prestataire",
        supplierCompanyLabel: "Raison social",
        supplierCapitalLabel: "Capital social",
        supplierStatusLabel: "Statut d'entreprise",
        supplierEmailLabel: "E-mail",
        supplierPhoneLabel: "Téléphone",
        supplierExternalLabel: "Prestataire externe",
        supplierHqLabel: "Maison Mére",
        supplierHqNameLabel: "Nom Maison Mére",
        supplierHqEmailLabel: "E-mail Maison Mére",
        supplierHqPhoneLabel: "Téléphone Maison Mére",
        supplierHqAddressLabel: "Adresse Maison Mére",
        supplierActivityFieldLabel: "Secteur d'activité",
        supplierActivityCodeLabel: "Code d'activité",
        supplierOfficeAddressLabel: "Adresse Siège sociale",
        supplierCommercialAddressLabel: "Adresse Commerciale",
        supplierCommercialAddress2Label: "Adresse Commerciale 2",
        supplierNifLabel: "N° IF",
        supplierNisLabel: "N° IS",
        supplierNrcLabel: "N° RC",

        supplierIntraHeading: "Liste des prestataires",
        supplierIntraManagementHeading: "Intra-entreprises",
        supplierIntraTableCaption: "Liste des prestataires",
        supplierIntraSearchHeading: "Rechercher un préstataire",
        supplierIntraEditHeading: "Gérer un préstataire",
        supplierIntraConfirmDelete: "Etes-vous sûre de vouloir supprimer ce partenaire ?",
        supplierIntraIdLabel: "ID",
        supplierIntraNameLabel: "Nom d'entreprise",
        supplierIntraPhoneLabel: "Téléphone",
        supplierIntraAddressLabel: "Adresse",
        supplierIntraNoteLabel: "Commentaires",

        employeeHeading: "Liste des employés",
        employeeManagementHeading: "Internes",
        employeeTableCaption: "Liste des employés",
        employeeSearchHeading: "Rechercher un employé",
        employeeEditHeading: "Gérer un employé",
        employeeConfirmDelete: "Etes-vous sûre de vouloir supprimer cet employé ?",
        employeeIdLabel: "ID",
        employeeCodeLabel: "Matricule",
        employeeLastNameLabel: "Nom",
        employeeFirstNameLabel: "Prénom",
        employeeFullNameLabel: "Nom et Prénom",
        employeeGenderLabel: "Civilité",
        employeeCivilStatusLabel: "Etat civile",
        employeeIdNumberLabel: "N° p. identité",
        employeePassportNumberLabel: "N° passeport",
        employeeEmailLabel: "E-mail",
        employeePhoneLabel: "Téléphone",
        employeeAddressLabel: "Adresse",
        employeeStructureTypeLabel: "Type de Structure",
        employeeStructureCodeLabel: "Structure",
        employeePositionIdLabel: "Poste",
        employeeNationalityLabel: "Nationalité",
        employeeAnalyticAccountCodeLabel: "Compte Analytique",
        employeeIsUserLabel: "Utilisateur",
        employeeIsDriverLabel: "Chauffeur",
        employeeIsHeadLabel: "Chef",

        employeeIntraHeading: "Liste des employés",
        employeeIntraManagementHeading: "Intra-entreprise",
        employeeIntraTableCaption: "Liste des employés",
        employeeIntraSearchHeading: "Rechercher un employé",
        employeeIntraEditHeading: "Gérer un employé",
        employeeIntraConfirmDelete: "Etes-vous sûre de vouloir supprimer cet employé ?",
        employeeIntraSupplierIntraIdLabel: "Structure",
        employeeIntraIdLabel: "ID",
        employeeIntraCodeLabel: "Matricule",
        employeeIntraLastNameLabel: "Nom",
        employeeIntraFirstNameLabel: "Prénom",
        employeeIntraFullNameLabel: "Nom et Prénom",
        employeeIntraGenderLabel: "Civilité",
        employeeIntraIdNumberLabel: "N° p. identité",
        employeeIntraNationalityLabel: "Nationalité",
        employeeIntraJobLabel: "Poste",

        employeeSupplierHeading: "Liste des employés",
        employeeSupplierManagementHeading: "Prestataire",
        employeeSupplierTableCaption: "Liste des employés",
        employeeSupplierSearchHeading: "Rechercher un employé",
        employeeSupplierEditHeading: "Gérer un employé",
        employeeSupplierConfirmDelete: "Etes-vous sûre de vouloir supprimer cet employé ?",
        employeeSupplierSupplierIdLabel: "Prestataire",
        employeeSupplierIdLabel: "ID",
        employeeSupplierCodeLabel: "Matricule",
        employeeSupplierLastNameLabel: "Nom",
        employeeSupplierFirstNameLabel: "Prénom",
        employeeSupplierFullNameLabel: "Nom et Prénom",
        employeeSupplierGenderLabel: "Civilité",
        employeeSupplierCivilStatusLabel: "Etat civile",
        employeeSupplierEmailLabel: "E-mail",
        employeeSupplierPhoneLabel: "Téléphone",
        employeeSupplierAddressLabel: "Adresse",
        employeeSupplierIdNumberLabel: "N° p. identité",
        employeeSupplierPassportNumberLabel: "N° passeport",
        employeeSupplierPositionIdLabel: "Poste",
        employeeSupplierNationalityLabel: "Nationalité",
        employeeSupplierIsUserLabel: "Utilisateur",
        employeeSupplierIsDriverLabel: "Chauffeur",

        warehouseHeading: "Magasins",
        warehouseManagementHeading: "Magasins",
        warehouseTableCaption: "Liste des magasins",
        warehouseSearchHeading: "Rechercher un magasin",
        warehouseEditHeading: "Gérer un magasin",
        warehouseConfirmDelete: "Etes-vous sûre de vouloir supprimer ce magasin ?",
        warehouseIdLabel: "ID",
        warehouseNameLabel: "Désignation",
        warehouseInfrastructureL1IdLabel: "Region",
        warehouseInfrastructureL2IdLabel: "Site",
        warehouseInfrastructureL3IdLabel: "Zone",
        warehouseInfrastructureIdLabel: "Bloc",
        warehouseTypeIdLabel: "Type",
        warehouseDescriptionLabel: "Description",

        productHeading: "Produits",
        productManagementHeading: "Produits",
        productTableCaption: "Liste des produits",
        productSearchHeading: "Rechercher un employé",
        productEditHeading: "Gérer un produit",
        productConfirmDelete: "Etes-vous sûre de vouloir supprimer ce produit ?",
        productCheckAttribute: "Veuillez d'abord créer des attributs de produit !",
        productIdLabel: "ID",
        productNameLabel: "Désignation",
        productBarcodeLabel: "Code-barres",
        productCodeLabel: "Référence",
        productBrandLabel: "Marque",
        productCategoryIdLabel: "Catégorie",
        productLowStockLevelLabel: "Seuil stock faible",
        productHasLotLabel: "Gestion par lot",
        productPerishableLabel: "Produit périssable",
        productHasVariationsLabel: "Produit variable",
        productAttributeLabel: "Attribut",
        productTermsLabel: "Terms",
        productAttributesLabel: "Attributs",
        productVariationsLabel: "Variations",
        productDescriptionLabel: "Description",
        productValueLabel: "Valeur",
        productQuantityLabel: "Quantité",
        productAvailabilityLabel: "Disponibilité",

        productCategoryHeading: "Catégories produit",
        productCategoryEditHeading: "Catégories de produit",
        productCategoryTableCaption: "Liste des catégories produit",
        productCategoryL1Label: "Catégorie",
        productCategoryL2Label: "Sous-catégorie",
        productCategoryL3Label: "Famille",
        productCategoryL4Label: "Sous-famille",
        productCategoryL1NameLabel: "Nom catégorie",
        productcategoryL1DescriptionLabel: "Description catégorie",
        productCategoryL2NameLabel: "Nom sous-catégorie",
        productCategoryL2DescriptionLabel: "Description sous-catégorie",
        productCategoryL3NameLabel: "Nom famille",
        productCategoryL3DescriptionLabel: "Description famille",
        productCategoryL4NameLabel: "Nom sous-famille",
        productCategoryL4DescriptionLabel: "Description sous-famille",
        productCategoryConfirmDelete: "Etes-vous sûre de vouloir supprimer cette catégorie ?",
        productCategoryDoesNotExist: "Cette catégorie n'existe pas !",

        productAttributeHeading: "Attributs de produits",
        productAttributeManagementHeading: "Attributs de produits",
        productAttributeTableCaption: "Liste des attributs",
        productAttributeEditHeading: "Gérer un attribut",
        productAttributeConfirmDelete: "Etes-vous sûre de vouloir supprimer cet attribut ?",
        productAttributeIdLabel: "ID",
        productAttributeNameLabel: "Nom",
        productAttributeOptionsLabel: "Options",

        productInputHeading: "Entrées de stock",
        productInputManagementHeading: "Entrées de stock",
        productInputTableCaption: "Historique des entrées de stock",
        productInputEditHeading: "Gérer une entrée de stock",
        productInputConfirmDelete: "Etes-vous sûre de vouloir supprimer cette entrée de stock ?",
        productInputIdLabel: "ID",
        productInputDateLabel: "Date",
        productInputCodeLabel: "Référence",
        productInputDfCodeLabel: "Bon de livraison",
        productInputPoCodeLabel: "Bon de commande",
        productInputDescriptionLabel: "Description",
        productInputProductsLabel: "Produits",
        productInputProductIdLabel: "Produit",
        productInputQuantityLabel: "Quantité",
        productInputWarehouseIdLabel: "Magasin",
        productInputLotCodeLabel: "N° lot",
        productInputExpiresLabel: "Expire le",
        productInputUnitPriceLabel: "Prix Unitaire",

        productOutputHeading: "Sorties de stock",
        productOutputManagementHeading: "Sorties de stock",
        productOutputTableCaption: "Historique des sorties de stock",
        productOutputEditHeading: "Gérer une sortie de stock",
        productOutputSearchHeading: "Rechercher une sortie de stock",
        productOutputConfirmDelete: "Etes-vous sûre de vouloir supprimer cette sortie de stock ?",
        productOutputIdLabel: "ID",
        productOutputStatusLabel: "Statut",
        productOutputDatetimeLabel: "Date et heure",
        productOutputEmployeeIdLabel: "Employé",
        productOutputKnownEmployeeLabel: "Interne",
        productOutputLastNameLabel: "Nom Employé",
        productOutputFirstNameLabel: "Prénom Employé",
        productOutputJobLabel: "Position Employé",
        productOutputNoteLabel: "Remarque",
        productOutputProductsLabel: "Produits",
        productOutputProductIdLabel: "Produit",
        productOutputQuantityLabel: "Quantité",
        productOutputLabel: "Sortir",

        productAdjustmentHeading: "Ajustements de stock",
        productAdjustmentManagementHeading: "Ajustements de stock",
        productAdjustmentTableCaption: "Historique des ajustements de stock",
        productAdjustmentEditHeading: "Gérer un ajustement de stock",
        productAdjustmentConfirmDelete: "Etes-vous sûre de vouloir supprimer cet ajustement de stock ?",
        productAdjustmentEditLabel: "Ajouter",
        productAdjustmentListLabel: "Historique",
        productAdjustmentIdLabel: "ID",
        productAdjustmentDateLabel: "Date",
        productAdjustmentProductIdLabel: "Produit",
        productAdjustmentStatusLabel: "Statut",
        productAdjustmentQuantityLabel: "Quantité",
        productAdjustmentOperationLabel: "Sens",
        productAdjustmentNoteLabel: "Remarque",
        productAdjustmentVariationsLabel: "Variations produit",
        productAdjustmentVariationIdLabel: "Variation produit",

        analyticAccountHeading: "Liste des comptes analytiques",
        analyticAccountManagementHeading: "Comptes internes",
        analyticAccountManagementExternHeading: "Comptes externes",
        analyticAccountTableCaption: "Liste des comptes",
        analyticAccountSearchHeading: "Rechercher un compte analytique",
        analyticAccountEditHeading: "Gérer un compte analytique",
        analyticAccountConfirmDelete: "Etes-vous sûre de vouloir supprimer ce compte analytique ?",
        analyticAccountIdLabel: "ID",
        analyticAccountCodeLabel: "Compte",
        analyticAccountNameLabel: "Désignation",
        analyticAccountStructureTypeLabel: "Type de Structure",
        analyticAccountStructureCodeLabel: "Structure",
        analyticAccountDescriptionLabel: "Description",

        analyticAccountIntraHeading: "Liste des comptes analytiques",
        analyticAccountIntraManagementHeading: "Intra-entreprises",
        analyticAccountIntraTableCaption: "Liste des comptes",
        analyticAccountIntraSearchHeading: "Rechercher un compte analytique",
        analyticAccountIntraEditHeading: "Gérer un compte analytique",
        analyticAccountIntraConfirmDelete: "Etes-vous sûre de vouloir supprimer ce compte analytique ?",
        analyticAccountIntraIdLabel: "ID",
        analyticAccountIntraCodeLabel: "Compte",
        analyticAccountIntraNameLabel: "Désignation",
        analyticAccountIntraSupplierIntraIdLabel: "Structure",
        analyticAccountIntraDescriptionLabel: "Description",

        vehicleHeading: "Liste des véhicules",
        vehicleManagementHeading: "Véhicules",
        vehicleTableCaption: "Liste des véhicules",
        vehicleSearchHeading: "Rechercher un véhicule",
        vehicleEditHeading: "Gérer un véhicule",
        vehicleConfirmDelete: "Etes-vous sûre de vouloir supprimer ce véhicule ?",
        vehicleInfosHeading: "Infos",
        vehicleInsurancesHeading: "Assurances",
        vehicleControlsHeading: "Contrôles",
        vehicleAssignmentsHeading: "Affectation",
        vehicleMissionsHeading: "Missions",
        vehicleRepairsHeading: "Maintenances",
        vehicleIdLabel: "ID",
        vehicleExternalLabel: "Externe",
        vehicleTypeIdLabel: "Type",
        vehicleBrandIdLabel: "Marque",
        vehicleModelIdLabel: "Modèle",
        vehicleYearLabel: "Année",
        vehicleColorIdLabel: "Couleur",
        vehicleFuelIdLabel: "Energie",
        vehicleGearboxIdLabel: "Boîte à vitesses",
        vehicleEpcLabel: "Code EPC",
        vehicleVinLabel: "N° châssis",
        vehicleVrnLabel: "N° d'immatriculation",
        vehicleNoteLabel: "Remarques",

        vehicleInsuranceHeading: "Historique des assurances",
        vehicleInsuranceManagementHeading: "Assurances",
        vehicleInsuranceTableCaption: "Historique des assurances",
        vehicleMaintenanceTableCaption: "Historique des maintenances",
        vehicleInsuranceEditHeading: "Gérer un assurance",
        vehicleInsuranceConfirmDelete: "Etes-vous sûre de vouloir supprimer cette assurance ?",
        vehicleInsuranceIdLabel: "ID",
        vehicleInsuranceVehicleIdLabel: "Véhicule",
        vehicleInsuranceCompanyIdLabel: "Société d'assurance",
        vehicleInsuranceAgencyNameLabel: "Agence",
        vehicleInsuranceAgencyCodeLabel: "N° Agence",
        vehicleInsurancePolicyNumberLabel: "Numéro de police",
        vehicleInsuranceBeginsLabel: "Date début",
        vehicleInsuranceEndsLabel: "Date fin",
        vehicleInsuranceAmountLabel: "Montant payé",

        vehicleDamageHeading: "Sinistres véhicule",
        vehicleDamageManagementHeading: "Sinistres véhicule",
        vehicleDamageTableCaption: "Historique des sinistres véhicule",
        vehicleDamageEditHeading: "Gérer un sinistre véhicule",
        vehicleDamageConfirmDelete: "Etes-vous sûre de vouloir supprimer ce sinistre véhicule ?",
        vehicleDamageEditLabel: "Ajouter",
        vehicleDamageListLabel: "Historique",
        vehicleDamageIdLabel: "ID",
        vehicleDamageVehicleIdLabel: "Véhicule",
        vehicleDamageInsuranceIdLabel: "Police d'assurance",
        vehicleDamageDescriptionLabel: "Dégâts",
        vehicleDamageDateLabel: "Date",
        vehicleDamageConditionIdLabel: "Etat du véhicule",
        vehicleDamageLocationLabel: "Endroit",
        vehicleDamageLocationFromLabel: "Allant de",
        vehicleDamageLocationToLabel: "vers",

        vehicleControlHeading: "Historique des contrôles techniques",
        vehicleControlManagementHeading: "Contrôles techniques",
        vehicleControlTableCaption: "Historique des contrôles techniques",
        vehicleControlEditHeading: "Gérer un contrôle technique",
        vehicleControlConfirmDelete: "Etes-vous sûre de vouloir supprimer ce contrôle technique ?",
        vehicleControlIdLabel: "ID",
        vehicleControlVehicleIdLabel: "Véhicule",
        vehicleControlAgencyIdLabel: "Agence",
        vehicleControlCodeLabel: "N° de contrôle",
        vehicleControlDateLabel: "Date",
        vehicleControlExpiresLabel: "Expiration",
        vehicleControlCheckpointsLabel: "Points de contrôle",
        vehicleControlReinspectionLabel: "Contre visite",
        vehicleControlReinspectionCheckpointsLabel: "Points de contrôle à réviser",
        vehicleControlReinspectionNoteLabel: "Remarque",

        vehicleAssignmentHeading: "Historique des affectations",
        vehicleAssignmentManagementHeading: "Affectations",
        vehicleAssignmentTableCaption: "Historique des affectations",
        vehicleAssignmentEditHeading: "Gérer une affectation",
        vehicleAssignmentConfirmDelete: "Etes-vous sûre de vouloir supprimer cette affectation ?",
        vehicleAssignmentStructureTypeLabel: "Type de Structure",
        vehicleAssignmentStructureCodeLabel: "Structure",
        vehicleAssignmentIdLabel: "ID",
        vehicleAssignmentVehicleIdLabel: "Véhicule",
        vehicleAssignmentEmployeeIdLabel: "Employé",
        vehicleAssignmentDriverIdLabel: "Conducteur",
        vehicleAssignmentBeginsLabel: "Date début",
        vehicleAssignmentEndsLabel: "Date fin",

        vehicleMaintenanceEditHeading:"Gérer une maintenance",
        vehicleMaintenanceDateBegin:"Date debut",
        vehicleMaintenanceDateEnd:"Date fin",
        vehicleMaintenanceTypeId:"Type de maintenance",
        vehicleMaintenanceBreakdownId:"Type de panne",
        vehicleMaintenanceEmployeeId:"Employé intervenant",
        vehicleMaintenanceEvalMaint:"Evaluation de la maintenance",
        vehicleMaintenanceSparePartId:"Pièce de rechange",
        vehicleMaintenanceCostMaint:"Coût de la maintenance",
        vehicleMaintenanceNbrMaint:"Nombre d'heure de la maintenance",
        vehicleMaintenanceStatus:"État",
        vehicleMaintenanceAnalyticAccount:"Compte analytique",
        vehicleMaintAlertMsg:"Veuillez remplir les champs sous dessus après avoir terminé la maintenance",

        vehicleMissionHeading: "Historique des missions",
        vehicleMissionManagementHeading: "Missions",
        vehicleMissionTableCaption: "Historique des missions",
        vehicleMissionEditHeading: "Gérer une mission",
        vehicleMissionConfirmDelete: "Etes-vous sûre de vouloir supprimer cette mission ?",
        vehicleMissionIdLabel: "ID",
        vehicleMissionDriverIdLabel: "Conducteur",
        vehicleMissionItineraryIdLabel: "Iténeraire",
        vehicleMissionExternalLabel: "Externe",
        vehicleMissionStructureTypeLabel: "Type de Structure",
        vehicleMissionStructureCodeLabel: "Structure",
        vehicleMissionSupplierIntraIdLabel: "Entreprises Externes",
        vehicleMissionBeginsLabel: "Date début",
        vehicleMissionEndsLabel: "Date fin",

        requestMaintenanceManagementHeading: "Gestion Des Requêtes (Maintenance) ",
        maintenanceHeading: "Liste des requêtes",
        maintenanceManagementHeading: "Requêtes",
        maintenanceTableCaption: "Liste des requêtes (Maintenance)",
        maintenanceSearchHeading: "Rechercher une requête",
        maintenanceEditHeading: "Gérer une requête",
        maintenanceOrderHeading: "Gérer une requête (Ordre de maintenance)",
        maintenanceConfirmDelete: "Etes-vous sûre de vouloir supprimer cette requêtes (Maintenance) ?",
        maintenanceStateBtnValidated:"Validé",
        maintenanceStateBtnAccepted:"Accepté",
        maintenanceStateBtnProgress:"En cours",
        maintenanceStateBtnRejected:"Rejeté",
        maintenanceStateLabel:"Mise a jour statut de la demande",


        requestMaintenanceTypeIdLabel: "Type de maintenance",
        requestMaintenanceEmergencyLabel: "Urgence",
        requestMaintenanceDescriptionLabel: "Note",
        requestMaintenanceFileLabel:"Attachement",
        requestMaintenanceStateLabel: "État",
        requestMaintenanceBreakdownIdLabel: "Type de panne",
        requestMaintenanceStructureCodeLabel: "Direction",
        requestMaintenanceEmployeeIdLabel: "Employé",
        requestMaintenanceanalyticAccountsLabel:"Compte analytic",
        requestMaintenanceAnalyticAccountTypeLabel:"Type de compte analytic",
        requestMaintenanceExecutionTypeLabel:"Responsable d'exécution",
        requestMaintenanceExecutionTypeSonatrachLabel:"SONATRACH",
        requestMaintenanceExecutionTypeProviderLabel:"Prestataire",
        requestMaintenanceParkLabel:"Atelier",
        requestMaintenanceParkHeadLabel:"Chef atelier",
        requestMaintenanceDisplayFileLabel:"Voir le fichier",


        orderMaintenanceBeginDateLabel:"Date début d'intervnetion",
        orderMaintenanceEndDateLabel:"Date fin d'intervnetion",
        orderMaintenanceHourLabel:"Heure fin d'intervnetion",
        orderMaintenanceNoteLabel:"Note",
        orderMaintenanceEmployeeIdLabel: "Employé",


        requestTransportMotifLabel:"Motif de la demande",
        requestTransportFromLabel:"Arrive de",
        requestTransportArrivalDateLabel:"Date d'arrivée",
        requestTransportDepartureDateLabel:"Date de départ",



        emergencyType1: "Urgent",
        emergencyType2: "Devrait être fait bientôt",
        emergencyType3: "Peut attendre les vacances",


        requestTransportManagementHeading: "Gestion Des Requêtes (Transport) ",
        transportHeading: "Liste des requêtes",
        transportManagementHeading: "Requêtes",
        transportTableCaption: "Liste des requêtes (Transport)",
        transportSearchHeading: "Rechercher une requête (Transport)",
        transportEditHeading: "Gérer une requête (Transport)",
        transportConfirmDelete: "Etes-vous sûre de vouloir supprimer cette requêtes (Transport) ?",


        branchesLabel:"Branches",
        parkEditHeading: "Gérer un atelier",
        parkConfirmDelete:"Etes-vous sûre de vouloir supprimer cette atelier ?",

        parkManagementHeading: "Gestion Des Ateliers",
        parkNameLabel: "Nom",
        parkPhoneLabel: "Numéro de Téléphone",
        parkEmployeeIdLabel:"Chef atelier",
        parkAddresseLabel: "Adresse",
        parkInfraLabel: "Infrastructure",
        parkInfraTypeLabel: "Type infrastructure",
        parkHeadLabel: "Chef atelier",
        parkDescLabel: "Note",
        parkInfraType1Label:"Region",
        parkInfraType2Label:"Site",
        parkInfraType3Label:"Zone",
        parkInfraType4Label:"Bloc",
        parkInfraType5Label:"Unité",
        parkBrancheNameLabel:"Nom",
        parkBrancheHeadingLabel:"Rubrique",
        parkBranchePhoneLabel:"Numéro",
        parkBrancheHeadLabel:"Chef branche",


        contractManagementHeading: "Contrats",
        contractConfirmDelete: "Etes-vous sûre de vouloir supprimer cette contrat ?",
        contractCodeLabel:"N° Contrat",
        contractDesignationLabel:"Désignation",
        contractObjectLabel:"Objet",
        contractSupplierTypeLabel:"Type de Prestation",
        contractSupplierLabel:"Prestataire",
        contractFileLabel:"Attachement",
        contractDateBeginLabel:"Date début de validité",
        contractDateEndLabel:"Date d'expiration",
        contractDateSignLabel:"Date de signature",
        // contractDateEffLabel:"Date d'entrée en vigueur",
        contractDateEffLabel:"Date effective",
        contractClientDomLabel:"Domiciliation Client",
        contractClientRepLabel:"Représentant Client ",
        contractSupplierDomLabel:"Domiciliation Prestataire",
        contractSupplierRepLabel:"Représentant Prestataire",
        contractNoteLabel:"Note",

        contractPriceScheduleMaintTableCaption: "Historique des maintenances",
        contractPriceScheduleRentTableCaption: "Historique des locations",

        contractPriceScheduleRentTypeIdLabel:"Type véhicule",
        contractPriceScheduleRentBrandLabel:"Marque",
        contractPriceScheduleRentEstimatedPriceLabel:"Prix estimé",
        contractPriceScheduleRentContractualQuantityLabel:"Quantité contractuelle",
        contractPriceScheduleRentAnnualQuantityLabel:"Quantité annuel",
        contractPriceScheduleRentForecastQuantityLabel:"Quantité estimé",



        contractAspectTotalAmountLabel:"Montant Total",
        contractAspectLocationAmountLabel:"Montant Location",
        contractAspectMaintenanceAmountLabel:"Montant Maintenance",
        contractAspectLocationLabel:"Location",
        contractAspectMaintenanceLabel:"Maintenance",
        historycontractAspectLocationLabel:"Historique des Location",
        historycontractAspectMaintenanceLabel:"Historique des Maintenance",

        contractTableCaption: "Liste des contrats",
        contractEditHeading: "Gérer une contrat",
        contractContractualScopeHeading:"Périmètre Contractuel",
        contractFinAspectHeading:"Aspects Financiers",
        contractPriceHeading:"Bordereau des prix",
        contractGuaranteeHeading:"Pénalités & Garantie",
        contractAlertHeading:"Notifications & Alertes",
        contractSynopHeading:"Synoptique",






        dropdownOptionHeading: "Listes déroulantes",
        dropdownOptionManagementHeading: "Contrôles techniques",
        dropdownOptionTableCaption: "Liste des options",
        dropdownOptionSelectOptionMessage: "Veuillez choisir quel élément de liste déroulante vous voulez modifier.",
        dropdownOptionIdLabel: "ID",
        dropdownOptionNameLabel: "Désignation",
        dropdownOptionDescriptionLabel: "Description",
        dropdownOptionParentLabel: "Parent",
        dropdownOptionCreatedByLabel: "Créé par",
        dropdownOptionDisplayLabel: "Affiché",

        roleHeading: "Liste des rôles",
        roleManagementHeading: "Rôles",
        roleTableCaption: "Liste des rôles",
        roleSearchHeading: "Rechercher un rôle",
        roleEditHeading: "Gérer un rôle",
        roleConfirmDelete: "Etes-vous sûre de vouloir supprimer ce rôle ?",
        roleIdLabel: "ID",
        roleNameLabel: "Nom",
    },
    ar: {
        dashboard: "Tableau de board",
        account: "Compte",
        settings: "Paramètres",
        signIn: "S'identifier",
        signUp: "Inscrivez-vous",
        passwordReset: "Réinitialisation du mot de passe",
        error404: "Erreur 404",
        error500: "Erreur 500",

        title: "Title",
        currencyDz: "DA",
        ExternalLabel: "externe",
        hour:"heure",
        unit: "Unité",
        undefined: "Aucun",
        genderMale: "Male",
        genderFemale: "Female",
        operationIncrease: "Addition",
        operationDecrease: "Soustraction",
        civilStatusMarried: "Married",
        civilStatusDivorced: "Divorced",
        civilStatusSingle: "Single",
        civilStatusWidower: "Widower",
        availabilityStockout: "Rupture",
        availabilityAvailable: "Disponible",
        availabilityStocklow: "Seuil faible",

        actions: "Actions",
        yes:"Oui",
        no:"Non",
        search: "Rechercher",
        searchHeadPark:"Rechercher  employée",
        searchHeadBranche:"Chef branche",
        add: "Add",
        confirm: "Confirm",
        select: "Select",
        cancel: "Cancel",
        save: "Save",
        loading: "Loading...",
        pleaseWait: "Please wait...",

        now: "Now",
        pickDate: "Pick a date",

        infoHeading: "Info !",

        errorHeading: "Error !",
        errorConnectionMessage: "An error has occurred, please check your internet connection. Then reload the page.",

        warningHeading: "Warning !",

        tableEmpty: "No items found to display in table.",
        paginationText: "Total items {total}",
        paginationSearchText: "Total items found {total} on {exists} exisiting",

        labelQueryString: "Key words",
        labelDescription: "Description",
        labelName: "Name",

        idCard1Label:"Carte nationale",
        idCard2Label:"Passport",
        idCard3Label:"Permis de conduire",

        carType1Label:"VIP",
        carType2Label:"Ordinnaire",
        carType3Label:"Missionnaire",
        guestsLabel:"Visiteurs",

        masterDataHeading: "Master Data",
        referencialsHeading: "Référentiels",
        externalCompaniesManagementHeading: "Gestion Des Entreprises Externes",
        employeesManagementHeading: "Gestion Des Employés",
        stockManagementHeading: "Gestion de stock",
        analyticAccountsManagementHeading: "Gestion Des Comptes Analytiques",
        servicesHeading: "Catalogue Services",
        settingsHeading: "Configuration",
        requestHeading: "Requête",
        fleetHeading: "Flotte",

        structureHeading: "Structures Management",
        structureEditHeading: "Edit structure",
        structureTableCaption: "Structures list",
        structureL1Label: "Direction",
        structureL2Label: "Division",
        structureL3Label: "Services",
        structureL4Label: "Service",
        structureL5Label: "Section",
        structureL1NameLabel: "Nom direction",
        structureL1DescriptionLabel: "Description direction",
        structureL2NameLabel: "Nom division",
        structureL2DescriptionLabel: "Description division",
        structureL3NameLabel: "Nom services",
        structureL3DescriptionLabel: "Description services",
        structureL4NameLabel: "Nom service",
        structureL4DescriptionLabel: "Description service",
        structureL5NameLabel: "Nom section",
        structureL5DescriptionLabel: "Description section",
        structureConfirmDelete: "Etes-vous sûre de vouloir supprimer cette structure ?",
        structureDoesNotExist: "Cette structure n'existe pas !",

        infrastructureHeading: "Infrastructures Management",
        infrastructureEditHeading: "Edit infrastructure",
        infrastructureTableCaption: "Infrastructures list",
        infrastructureL1Label: "Region",
        infrastructureL2Label: "Site",
        infrastructureL3Label: "Zone",
        infrastructureL4Label: "Bloc",
        infrastructureL5Label: "Unité",
        infrastructureL1NameLabel: "Nom region",
        infrastructureL1DescriptionLabel: "Description region",
        infrastructureL2NameLabel: "Nom site",
        infrastructureL2DescriptionLabel: "Description site",
        infrastructureL3NameLabel: "Nom zone",
        infrastructureL3DescriptionLabel: "Description zone",
        infrastructureL4NameLabel: "Nom bloc",
        infrastructureL4DescriptionLabel: "Description bloc",
        infrastructureL5NameLabel: "Nom unité",
        infrastructureL5DescriptionLabel: "Description unité",
        infrastructureConfirmDelete: "Etes-vous sûre de vouloir supprimer cette infrastructure ?",
        infrastructureDoesNotExist: "Cette infrastructure n'existe pas !",

        supplierHeading: "Liste des prestataires",
        supplierManagementHeading: "Prestataires ou Fournisseurs",
        supplierTableCaption: "Liste des prestataires",
        supplierSearchHeading: "Rechercher un préstataire",
        supplierEditHeading: "Gérer un préstataire",
        supplierGeneralDataHeading: "Infos Générales",
        supplierRegulatoryDataHeading: "Infos Réglementaires",
        supplierConfirmDelete: "Etes-vous sûre de vouloir supprimer ce partenaire ?",
        supplierIdLabel: "ID",
        supplierCodeLabel: "Code prestataire",
        supplierNameLabel: "Nom de prestataire",
        supplierCompanyLabel: "Raison social",
        supplierCapitalLabel: "Capital social",
        supplierStatusLabel: "Statut d'entreprise",
        supplierEmailLabel: "E-mail",
        supplierPhoneLabel: "Téléphone",
        supplierExternalLabel: "Prestataire externe",
        supplierHqLabel: "Maison Mére",
        supplierHqNameLabel: "Nom Maison Mére",
        supplierHqEmailLabel: "E-mail Maison Mére",
        supplierHqPhoneLabel: "Téléphone Maison Mére",
        supplierHqAddressLabel: "Adresse Maison Mére",
        supplierActivityFieldLabel: "Secteur d'activité",
        supplierActivityCodeLabel: "Code d'activité",
        supplierOfficeAddressLabel: "Adresse Siège sociale",
        supplierCommercialAddressLabel: "Adresse Commerciale",
        supplierCommercialAddress2Label: "Adresse Commerciale 2",
        supplierNifLabel: "N° IF",
        supplierNisLabel: "N° IS",
        supplierNrcLabel: "N° RC",

        supplierIntraHeading: "Liste des prestataires",
        supplierLabel: "Prestataire",
        supplierIntraManagementHeading: "Intra-entreprises",
        supplierIntraTableCaption: "Liste des prestataires",
        supplierIntraSearchHeading: "Rechercher un préstataire",
        supplierIntraEditHeading: "Gérer un préstataire",
        supplierIntraConfirmDelete: "Etes-vous sûre de vouloir supprimer ce partenaire ?",
        supplierIntraIdLabel: "ID",
        supplierIntraNameLabel: "Nom d'entreprise",
        supplierIntraPhoneLabel: "Téléphone",
        supplierIntraAddressLabel: "Adresse",
        supplierIntraNoteLabel: "Commentaires",

        employeeHeading: "Liste des employés",
        employeeManagementHeading: "Internes",
        employeeTableCaption: "Liste des employés",
        employeeSearchHeading: "Rechercher un employé",
        employeeEditHeading: "Gérer un employé",
        employeeConfirmDelete: "Etes-vous sûre de vouloir supprimer cet employé ?",
        employeeIdLabel: "ID",
        employeeCodeLabel: "Matricule",
        employeeLastNameLabel: "Last name",
        employeeFirstNameLabel: "First name",
        employeeFullNameLabel: "Fullname",
        employeeGenderLabel: "Gender",
        employeeCivilStatusLabel: "Etat civile",
        employeeIdNumberLabel: "N° p. identité",
        employeePassportNumberLabel: "N° passeport",
        employeeEmailLabel: "E-mail",
        employeePhoneLabel: "Phone",
        employeeAddressLabel: "Address",
        employeeStructureTypeLabel: "Type de Structure",
        employeeStructureCodeLabel: "Structure",
        employeePositionIdLabel: "Job",
        employeeNationalityLabel: "Nationality",
        employeeAnalyticAccountCodeLabel: "Compte Analytique",
        employeeIsUserLabel: "User",
        employeeIsDriverLabel: "Driver",
        employeeIsHeadLabel: "Chef",

        employeeIntraHeading: "Liste des employés",
        employeeIntraManagementHeading: "Intra-entreprise",
        employeeIntraTableCaption: "Liste des employés",
        employeeIntraSearchHeading: "Rechercher un employé",
        employeeIntraEditHeading: "Gérer un employé",
        employeeIntraConfirmDelete: "Etes-vous sûre de vouloir supprimer cet employé ?",
        employeeIntraSupplierIntraIdLabel: "Structure",
        employeeIntraIdLabel: "ID",
        employeeIntraCodeLabel: "Matricule",
        employeeIntraLastNameLabel: "Nom",
        employeeIntraFirstNameLabel: "Prénom",
        employeeIntraFullNameLabel: "Nom et Prénom",
        employeeIntraGenderLabel: "Civilité",
        employeeIntraIdNumberLabel: "N° p. identité",
        employeeIntraNationalityLabel: "Nationalité",
        employeeIntraJobLabel: "Poste",

        employeeSupplierHeading: "Liste des employés",
        employeeSupplierManagementHeading: "Prestataire",
        employeeSupplierTableCaption: "Liste des employés",
        employeeSupplierSearchHeading: "Rechercher un employé",
        employeeSupplierEditHeading: "Gérer un employé",
        employeeSupplierConfirmDelete: "Etes-vous sûre de vouloir supprimer cet employé ?",
        employeeSupplierSupplierIdLabel: "Prestataire",
        employeeSupplierIdLabel: "ID",
        employeeSupplierCodeLabel: "Matricule",
        employeeSupplierLastNameLabel: "Nom",
        employeeSupplierFirstNameLabel: "Prénom",
        employeeSupplierFullNameLabel: "Nom et Prénom",
        employeeSupplierGenderLabel: "Civilité",
        employeeSupplierCivilStatusLabel: "Etat civile",
        employeeSupplierEmailLabel: "E-mail",
        employeeSupplierPhoneLabel: "Téléphone",
        employeeSupplierAddressLabel: "Adresse",
        employeeSupplierIdNumberLabel: "N° p. identité",
        employeeSupplierPassportNumberLabel: "N° passeport",
        employeeSupplierPositionIdLabel: "Poste",
        employeeSupplierNationalityLabel: "Nationalité",
        employeeSupplierIsUserLabel: "Utilisateur",
        employeeSupplierIsDriverLabel: "Chauffeur",

        warehouseHeading: "Magasins",
        warehouseManagementHeading: "Magasins",
        warehouseTableCaption: "Liste des magasins",
        warehouseSearchHeading: "Rechercher un magasin",
        warehouseEditHeading: "Gérer un magasin",
        warehouseConfirmDelete: "Etes-vous sûre de vouloir supprimer ce magasin ?",
        warehouseIdLabel: "ID",
        warehouseNameLabel: "Désignation",
        warehouseInfrastructureL1IdLabel: "Region",
        warehouseInfrastructureL2IdLabel: "Site",
        warehouseInfrastructureL3IdLabel: "Zone",
        warehouseInfrastructureIdLabel: "Bloc",
        warehouseTypeIdLabel: "Type",
        warehouseDescriptionLabel: "Description",

        productHeading: "Produits",
        productManagementHeading: "Produits",
        productTableCaption: "Liste des produits",
        productSearchHeading: "Rechercher un employé",
        productEditHeading: "Gérer un produit",
        productConfirmDelete: "Etes-vous sûre de vouloir supprimer ce produit ?",
        productCheckAttribute: "Veuillez d'abord créer des attributs de produit !",
        productIdLabel: "ID",
        productNameLabel: "Désignation",
        productBarcodeLabel: "Code-barres",
        productCodeLabel: "Référence",
        productBrandLabel: "Marque",
        productCategoryIdLabel: "Catégorie",
        productLowStockLevelLabel: "Seuil stock faible",
        productHasLotLabel: "Gestion par lot",
        productPerishableLabel: "Produit périssable",
        productHasVariationsLabel: "Produit variable",
        productAttributesLabel: "Attributs",
        productVariationsLabel: "Variations",
        productDescriptionLabel: "Description",
        productValueLabel: "Valeur",
        productQuantityLabel: "Quantité",
        productAvailabilityLabel: "Disponibilité",

        productCategoryHeading: "Product Categories",
        productCategoryEditHeading: "Edit product category",
        productCategoryTableCaption: "Product Categories list",
        productCategoryL1Label: "Category",
        productCategoryL2Label: "Sub-Category",
        productCategoryL3Label: "Familly",
        productCategoryL4Label: "Sub-Familly",
        productCategoryL1NameLabel: "Nom catégorie",
        productCategoryL1DescriptionLabel: "Description catégorie",
        productCategoryL2NameLabel: "Nom sous-catégorie",
        productCategoryL2DescriptionLabel: "Description sous-catégorie",
        productCategoryL3NameLabel: "Nom famille",
        productCategoryL3DescriptionLabel: "Description famille",
        productCategoryL4NameLabel: "Nom sous-famille",
        productCategoryL4DescriptionLabel: "Description sous-famille",
        productCategoryConfirmDelete: "Etes-vous sûre de vouloir supprimer cette catégorie ?",
        productCategoryDoesNotExist: "Cette catégorie n'existe pas !",

        productAttributeHeading: "Attributs de produits",
        productAttributeManagementHeading: "Attributs de produits",
        productAttributeTableCaption: "Liste des attributs",
        productAttributeEditHeading: "Gérer un attribut",
        productAttributeConfirmDelete: "Etes-vous sûre de vouloir supprimer cet attribut ?",
        productAttributeIdLabel: "ID",
        productAttributeNameLabel: "Nom",
        productAttributeOptionsLabel: "Options",

        productInputHeading: "Entrées de stock",
        productInputManagementHeading: "Entrées de stock",
        productInputTableCaption: "Historique des entrées de stock",
        productInputEditHeading: "Gérer une entrée de stock",
        productInputConfirmDelete: "Etes-vous sûre de vouloir supprimer cette entrée de stock ?",
        productInputIdLabel: "ID",
        productInputDateLabel: "Date",
        productInputCodeLabel: "Référence",
        productInputDfCodeLabel: "Bon de livraison",
        productInputPoCodeLabel: "Bon de commande",
        productInputDescriptionLabel: "Description",
        productInputProductsLabel: "Produits",
        productInputProductIdLabel: "Produit",
        productInputQuantityLabel: "Quantité",
        productInputWarehouseIdLabel: "Magasin",
        productInputLotCodeLabel: "N° lot",
        productInputExpiresLabel: "Expire le",
        productInputUnitPriceLabel: "Prix Unitaire",

        productOutputHeading: "Sorties de stock",
        productOutputManagementHeading: "Sorties de stock",
        productOutputTableCaption: "Historique des sorties de stock",
        productOutputEditHeading: "Gérer une sortie de stock",
        productOutputSearchHeading: "Rechercher une sortie de stock",
        productOutputConfirmDelete: "Etes-vous sûre de vouloir supprimer cette sortie de stock ?",
        productOutputIdLabel: "ID",
        productOutputStatusLabel: "Statut",
        productOutputDatetimeLabel: "Date et heure",
        productOutputEmployeeIdLabel: "Employé",
        productOutputKnownEmployeeLabel: "Interne",
        productOutputLastNameLabel: "Nom Employé",
        productOutputFirstNameLabel: "Prénom Employé",
        productOutputJobLabel: "Position Employé",
        productOutputNoteLabel: "Remarque",
        productOutputProductsLabel: "Produits",
        productOutputProductIdLabel: "Produit",
        productOutputQuantityLabel: "Quantité",
        productOutputLabel: "Sortir",

        productAdjustmentHeading: "Ajustements de stock",
        productAdjustmentManagementHeading: "Ajustements de stock",
        productAdjustmentTableCaption: "Historique des ajustements de stock",
        productAdjustmentEditHeading: "Gérer un ajustement de stock",
        productAdjustmentConfirmDelete: "Etes-vous sûre de vouloir supprimer cet ajustement de stock ?",
        productAdjustmentEditLabel: "Ajouter",
        productAdjustmentListLabel: "Historique",
        productAdjustmentIdLabel: "ID",
        productAdjustmentDateLabel: "Date",
        productAdjustmentProductIdLabel: "Produit",
        productAdjustmentStatusLabel: "Statut",
        productAdjustmentQuantityLabel: "Quantité",
        productAdjustmentOperationLabel: "Sens",
        productAdjustmentNoteLabel: "Remarque",
        productAdjustmentVariationsLabel: "Variations produit",
        productAdjustmentVariationIdLabel: "Variation produit",

        analyticAccountHeading: "Liste des comptes analytiques",
        analyticAccountManagementHeading: "Comptes internes",
        analyticAccountManagementExternHeading: "Comptes externes",
        analyticAccountTableCaption: "Liste des comptes",
        analyticAccountSearchHeading: "Rechercher un compte analytique",
        analyticAccountEditHeading: "Gérer un compte analytique",
        analyticAccountConfirmDelete: "Etes-vous sûre de vouloir supprimer ce compte analytique ?",
        analyticAccountIdLabel: "ID",
        analyticAccountCodeLabel: "Compte",
        analyticAccountNameLabel: "Désignation",
        analyticAccountStructureTypeLabel: "Type de Structure",
        analyticAccountStructureCodeLabel: "Structure",
        analyticAccountDescriptionLabel: "Description",

        analyticAccountIntraHeading: "Liste des comptes analytiques",
        analyticAccountIntraManagementHeading: "Intra-entreprises",
        analyticAccountIntraTableCaption: "Liste des comptes",
        analyticAccountIntraSearchHeading: "Rechercher un compte analytique",
        analyticAccountIntraEditHeading: "Gérer un compte analytique",
        analyticAccountIntraConfirmDelete: "Etes-vous sûre de vouloir supprimer ce compte analytique ?",
        analyticAccountIntraIdLabel: "ID",
        analyticAccountIntraCodeLabel: "Compte",
        analyticAccountIntraNameLabel: "Désignation",
        analyticAccountIntraSupplierIntraIdLabel: "Structure",
        analyticAccountIntraDescriptionLabel: "Description",

        vehicleHeading: "Liste des véhicules",
        vehicleManagementHeading: "Véhicules",
        vehicleTableCaption: "Liste des véhicules",
        vehicleSearchHeading: "Rechercher un véhicule",
        vehicleEditHeading: "Gérer un véhicule",
        vehicleConfirmDelete: "Etes-vous sûre de vouloir supprimer ce véhicule ?",
        vehicleInfosHeading: "Infos",
        vehicleInsurancesHeading: "Assurances",
        vehicleControlsHeading: "Contrôles",
        vehicleAssignmentsHeading: "Affectation",
        vehicleMissionsHeading: "Missions",
        vehicleRepairsHeading: "Maintenances",
        vehicleIdLabel: "ID",
        vehicleExternalLabel: "Externe",
        vehicleTypeIdLabel: "Type",
        vehicleBrandIdLabel: "Marque",
        vehicleModelIdLabel: "Modèle",
        vehicleYearLabel: "Année",
        vehicleColorIdLabel: "Couleur",
        vehicleFuelIdLabel: "Energie",
        vehicleGearboxIdLabel: "Boîte à vitesses",
        vehicleEpcLabel: "Code EPC",
        vehicleVinLabel: "N° châssis",
        vehicleVrnLabel: "N° d'immatriculation",
        vehicleNoteLabel: "Remarques",

        vehicleInsuranceHeading: "Historique des assurances",
        vehicleInsuranceManagementHeading: "Assurances",
        vehicleInsuranceTableCaption: "Historique des assurances",
        vehicleMaintenanceTableCaption: "Historique des maintenances",
        vehicleInsuranceEditHeading: "Gérer un assurance",
        vehicleInsuranceConfirmDelete: "Etes-vous sûre de vouloir supprimer cette assurance ?",
        vehicleInsuranceIdLabel: "ID",
        vehicleInsuranceVehicleIdLabel: "Véhicule",
        vehicleInsuranceCompanyIdLabel: "Société d'assurance",
        vehicleInsuranceAgencyNameLabel: "Agence",
        vehicleInsuranceAgencyCodeLabel: "N° Agence",
        vehicleInsurancePolicyNumberLabel: "Numéro de police",
        vehicleInsuranceBeginsLabel: "Date début",
        vehicleInsuranceEndsLabel: "Date fin",
        vehicleInsuranceAmountLabel: "Montant payé",

        vehicleDamageHeading: "Sinistres véhicule",
        vehicleDamageManagementHeading: "Sinistres véhicule",
        vehicleDamageTableCaption: "Historique des sinistres véhicule",
        vehicleDamageEditHeading: "Gérer un sinistre véhicule",
        vehicleDamageConfirmDelete: "Etes-vous sûre de vouloir supprimer ce sinistre véhicule ?",
        vehicleDamageEditLabel: "Ajouter",
        vehicleDamageListLabel: "Historique",
        vehicleDamageIdLabel: "ID",
        vehicleDamageVehicleIdLabel: "Véhicule",
        vehicleDamageInsuranceIdLabel: "Police d'assurance",
        vehicleDamageDescriptionLabel: "Dégâts",
        vehicleDamageDateLabel: "Date",
        vehicleDamageConditionIdLabel: "Etat du véhicule",
        vehicleDamageLocationLabel: "Endroit",
        vehicleDamageLocationFromLabel: "Allant de",
        vehicleDamageLocationToLabel: "vers",

        vehicleControlHeading: "Historique des contrôles techniques",
        vehicleControlManagementHeading: "Contrôles techniques",
        vehicleControlTableCaption: "Historique des contrôles techniques",
        vehicleControlEditHeading: "Gérer un contrôle technique",
        vehicleControlConfirmDelete: "Etes-vous sûre de vouloir supprimer ce contrôle technique ?",
        vehicleControlIdLabel: "ID",
        vehicleControlVehicleIdLabel: "Véhicule",
        vehicleControlAgencyIdLabel: "Agence",
        vehicleControlCodeLabel: "N° de contrôle",
        vehicleControlDateLabel: "Date",
        vehicleControlExpiresLabel: "Expiration",
        vehicleControlCheckpointsLabel: "Points de contrôle",
        vehicleControlReinspectionLabel: "Contre visite",
        vehicleControlReinspectionCheckpointsLabel: "Points de contrôle à réviser",
        vehicleControlReinspectionNoteLabel: "Remarque",

        vehicleAssignmentHeading: "Historique des affectations",
        vehicleAssignmentManagementHeading: "Affectations",
        vehicleAssignmentTableCaption: "Historique des affectations",
        vehicleAssignmentEditHeading: "Gérer une affectation",
        vehicleAssignmentConfirmDelete: "Etes-vous sûre de vouloir supprimer cette affectation ?",
        vehicleAssignmentStructureTypeLabel: "Type de Structure",
        vehicleAssignmentStructureCodeLabel: "Structure",
        vehicleAssignmentIdLabel: "ID",
        vehicleAssignmentVehicleIdLabel: "Véhicule",
        vehicleAssignmentEmployeeIdLabel: "Employé",
        vehicleAssignmentDriverIdLabel: "Conducteur",
        vehicleAssignmentBeginsLabel: "Date début",
        vehicleAssignmentEndsLabel: "Date fin",

        vehicleMaintenanceEditHeading:"Gérer une maintenance",
        vehicleMaintenanceDateBegin:"Date debut",
        vehicleMaintenanceDateEnd:"Date fin",
        vehicleMaintenanceTypeId:"Type de maintenance",
        vehicleMaintenanceBreakdownId:"Type de panne",
        vehicleMaintenanceEmployeeId:"Employé intervenant",
        vehicleMaintenanceEvalMaint:"Evaluation de la maintenance",
        vehicleMaintenanceSparePartId:"Pièce de rechange",
        vehicleMaintenanceCostMaint:"Coût de la maintenance",
        vehicleMaintenanceNbrMaint:"Nombre d'heure de la maintenance",
        vehicleMaintenanceStatus:"État",
        vehicleMaintenanceAnalyticAccount:"Compte analytique",
        vehicleMaintAlertMsg:"Veuillez remplir les champs sous dessus après avoir terminé la maintenance",


        vehicleMissionHeading: "Historique des missions",
        vehicleMissionManagementHeading: "Missions",
        vehicleMissionTableCaption: "Historique des missions",
        vehicleMissionEditHeading: "Gérer une mission",
        vehicleMissionConfirmDelete: "Etes-vous sûre de vouloir supprimer cette mission ?",
        vehicleMissionIdLabel: "ID",
        vehicleMissionDriverIdLabel: "Conducteur",
        vehicleMissionItineraryIdLabel: "Iténeraire",
        vehicleMissionExternalLabel: "Externe",
        vehicleMissionStructureTypeLabel: "Type de Structure",
        vehicleMissionStructureCodeLabel: "Structure",
        vehicleMissionSupplierIntraIdLabel: "Entreprises Externes",
        vehicleMissionBeginsLabel: "Date début",
        vehicleMissionEndsLabel: "Date fin",

        requestMaintenanceManagementHeading: "Gestion Des Requêtes (Maintenance) ",
        maintenanceHeading: "Liste des requêtes",
        maintenanceManagementHeading: "Requêtes",
        maintenanceTableCaption: "Liste des requêtes (Maintenance)",
        maintenanceSearchHeading: "Rechercher une requête (Maintenance)",
        maintenanceEditHeading: "Gérer une requête (Maintenance)",
        maintenanceOrderHeading: "Gérer une requête (Ordre de maintenance)",
        maintenanceConfirmDelete: "Etes-vous sûre de vouloir supprimer cette requêtes (Maintenance) ?",
        maintenanceStateBtnValidated:"Validé",
        maintenanceStateBtnAccepted:"Accepté",
        maintenanceStateBtnProgress:"En cours",
        maintenanceStateBtnRejected:"Rejeté",
        maintenanceStateLabel:"Mise a jour statut de la demande",

        requestMaintenanceTypeIdLabel: "Type de maintenance",
        requestMaintenanceEmergencyLabel: "Urgence",
        requestMaintenanceDescriptionLabel: "Note",
        requestMaintenanceFileLabel: "Attachement",
        requestMaintenanceStateLabel: "État",
        requestMaintenanceBreakdownIdLabel: "Type de panne",
        requestMaintenanceStructureCodeLabel: "Direction",
        requestMaintenanceEmployeeIdLabel: "Employé",
        requestMaintenanceanalyticAccountsLabel:"Compte analytic",
        requestMaintenanceAnalyticAccountTypeLabel:"Type de compte analytic",
        requestMaintenanceExecutionTypeLabel:"Responsable d'exécution",
        requestMaintenanceExecutionTypeSonatrachLabel:"SONATRACH",
        requestMaintenanceExecutionTypeProviderLabel:"Prestataire",
        requestMaintenanceParkLabel:"Atelier",
        requestMaintenanceParkHeadLabel:"Chef atelier",
        requestMaintenanceDisplayFileLabel:"Voir le fichier",

        orderMaintenanceBeginDateLabel:"Date début d'intervnetion",
        orderMaintenanceEndDateLabel:"Date fin d'intervnetion",
        orderMaintenanceHourLabel:"Heure fin d'intervnetion",
        orderMaintenanceNoteLabel:"Note",
        orderMaintenanceEmployeeIdLabel: "Employé",



        requestTransportMotifLabel:"Motif de la demande",
        requestTransportFromLabel:"Arrive de",
        requestTransportArrivalDateLabel:"Date d'arrivée",
        requestTransportDepartureDateLabel:"Date de départ",

        emergencyType1: "Urgent",
        emergencyType2: "Devrait être fait bientôt",
        emergencyType3: "Peut attendre les vacances",


        requestTransportManagementHeading: "Gestion Des Requêtes (Transport) ",
        transportHeading: "Liste des requêtes",
        transportManagementHeading: "Requêtes",
        transportTableCaption: "Liste des requêtes (Transport)",
        transportSearchHeading: "Rechercher une requête (Transport)",
        transportEditHeading: "Gérer une requête (Transport)",
        transportConfirmDelete: "Etes-vous sûre de vouloir supprimer cette requêtes (Transport) ?",



        branchesLabel:"Branches",
        parkEditHeading: "Gérer un atelier",
        parkConfirmDelete:"Etes-vous sûre de vouloir supprimer cette atelier ?",

        parkManagementHeading: "Gestion Des Ateliers",
        parkNameLabel: "Nom",
        parkPhoneLabel: "Numéro de Téléphone",
        parkEmployeeIdLabel:"Chef atelier",
        parkBrancheEmployeeIdLabel:"Chef branche",
        parkAddresseLabel: "Adresse",
        parkInfraLabel: "Infrastructure",
        parkInfraTypeLabel: "Type infrastructure",
        parkHeadLabel: "Chef atelier",
        parkDescLabel: "Note",
        parkInfraType1Label:"Region",
        parkInfraType2Label:"Site",
        parkInfraType3Label:"Zone",
        parkInfraType4Label:"Bloc",
        parkInfraType5Label:"Unité",
        parkBrancheNameLabel:"Nom",
        parkBrancheHeadingLabel:"Rubrique",
        parkBranchePhoneLabel:"Numéro",
        parkBrancheHeadLabel:"Chef branche",


        contractManagementHeading: "Contrats",
        contractConfirmDelete: "Etes-vous sûre de vouloir supprimer cette contrat ?",
        contractCodeLabel:"N° Contrat",
        contractDesignationLabel:"Désignation",
        contractObjectLabel:"Objet",
        contractSupplierTypeLabel:"Type de Prestation",
        contractSupplierLabel:"Prestataire",
        contractFileLabel:"Attachement",
        contractDateBeginLabel:"Date début de validité",
        contractDateEndLabel:"Date d'expiration",
        contractDateSignLabel:"Date de signature",
        // contractDateEffLabel:"Date d'entrée en vigueur",
        contractDateEffLabel:"Date effective",
        contractClientDomLabel:"Domiciliation Client",
        contractClientRepLabel:"Représentant Client ",
        contractSupplierDomLabel:"Domiciliation Prestataire",
        contractSupplierRepLabel:"Représentant Prestataire",
        contractNoteLabel:"Note",

        contractPriceScheduleMaintTableCaption: "Historique des maintenances",
        contractPriceScheduleRentTableCaption: "Historique des locations",

        contractPriceScheduleRentTypeIdLabel:"Type véhicule",
        contractPriceScheduleRentBrandLabel:"Marque",
        contractPriceScheduleRentEstimatedPriceLabel:"Prix estimé",
        contractPriceScheduleRentContractualQuantityLabel:"Quantité contractuelle",
        contractPriceScheduleRentAnnualQuantityLabel:"Quantité annuel",
        contractPriceScheduleRentForecastQuantityLabel:"Quantité estimé",


        contractAspectTotalAmountLabel:"Montant Total",
        contractAspectLocationAmountLabel:"Montant Location",
        contractAspectMaintenanceAmountLabel:"Montant Maintenance",
        contractAspectLocationLabel:"Location",
        contractAspectMaintenanceLabel:"Maintenance",
        historycontractAspectLocationLabel:"Historique des Location",
        historycontractAspectMaintenanceLabel:"Historique des Maintenance",

        contractTableCaption: "Liste des contrats",
        contractEditHeading: "Gérer une contrat",
        contractContractualScopeHeading:"Périmètre Contractuel",
        contractFinAspectHeading:"Aspects Financiers",
        contractPriceHeading:"Bordereau des prix",
        contractGuaranteeHeading:"Pénalités & Garantie",
        contractAlertHeading:"Notifications & Alertes",
        contractSynopHeading:"Synoptique",



        dropdownOptionHeading: "Listes déroulantes",
        dropdownOptionManagementHeading: "Listes déroulantes",
        dropdownOptionTableCaption: "Liste des options",
        dropdownOptionSelectOptionMessage: "Veuillez choisir quel élément de liste déroulante vous voulez modifier.",
        dropdownOptionIdLabel: "ID",
        dropdownOptionNameLabel: "Désignation",
        dropdownOptionDescriptionLabel: "Description",
        dropdownOptionParentLabel: "Parent",
        dropdownOptionCreatedByLabel: "Créé par",
        dropdownOptionDisplayLabel: "Affiché",

        roleHeading: "Liste des rôles",
        roleManagementHeading: "Rôles",
        roleTableCaption: "Liste des rôles",
        roleSearchHeading: "Rechercher un rôle",
        roleEditHeading: "Gérer un rôle",
        roleConfirmDelete: "Etes-vous sûre de vouloir supprimer ce rôle ?",
        roleIdLabel: "ID",
        roleNameLabel: "Nom",
    },
};

const i18n = createI18n({
    legacy: false,
    locale: "fr",
    globalInjection: true,
    messages,
});

export default i18n;
