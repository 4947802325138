import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { Actions } from "@/store/enums/StoreEnums";

export interface Locale {
    locale: string;
}

@Module
export default class LocaleModule extends VuexModule implements Locale {
    locale = "";

    get getLocale() {
        return this.locale;
    }

    @Mutation
    updateLocale(locale: string) {
        this.locale = locale;
    }

    @Action
    [Actions.SET_LOCALE](locale: string) {
        this.context.commit('updateLocale', locale);
    }
}
